import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Map, TileLayer, Popup, Marker, Circle } from "react-leaflet";
import L from "leaflet";
import "style/leaflet.css";
import { LocaleContext } from "context/locale-context";

function SyriaMap({ units, isLoading, incident }) {
  const locale = useContext(LocaleContext);
  const isAra = locale === "ar";
  const default_viewport = {
    lat: incident.latitude,
    lng: incident.longitude,
    zoom: 10,
  };

  //  const MarkerRadius = unit => (hoveredUnitID === unit.id ? 15000 : 6000);
  //  const MarkerColor = unit =>  (hoveredUnitID === unit.id ? "red" : "white");
  const position = [default_viewport.lat, default_viewport.lng];

  if (typeof window !== "undefined") {
    return (
      <>
        {isLoading ? (
          <div>Loading</div>
        ) : (
          <Map
            center={position}
            zoom={default_viewport.zoom}
            className="leaflet-map"
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* units.map(unit => { */
            /*   if ((unit.latitude, unit.longitude)) { */
            /*     return ( */
            /*       <Marker */
            /*         key={unit.id} */
            /*         position={[unit.latitude, unit.longitude]} */
            /*         icon={L.icon({ */
            /*           iconUrl: "/assets/SA.png", */
            /*           iconAnchor: [0, 0] */
            /*         })} */
            /*       > */
            /*         <Popup> */
            /*           <span> */
            /*             <LocalizedLink */
            /*               to={`datasets/incident/details/${incident.id}`} */
            /*               state={{ incident: incident }} */
            /*             > */
            /*               {isAra ? unit.title_ar : unit.title_en} */
            /*             </LocalizedLink> */
            /*           </span> */
            /*         </Popup> */
            /*       </Marker> */
            /*     ); */
            /*   } */
            /*   return null; */
            /* }) */}
            <Marker
              position={[incident.latitude, incident.longitude]}
              icon={L.icon({
                iconUrl: "/assets/SA.png",
                iconAnchor: [0, 0],
              })}
              opacity="0"
            >
              <Popup>
                <span>{isAra ? incident.title_ar : incident.title_en}</span>
              </Popup>
              <Circle
                center={{ lat: incident.latitude, lng: incident.longitude }}
                fillColor="#b33a00"
                radius={5000}
                fill={true}
                stroke={false}
                opacity={0}
                fillOpacity={0.7}
              />
            </Marker>
          </Map>
        )}
      </>
    );
  }
  return null;
}

SyriaMap.propTypes = {
  units: PropTypes.array,
  isLoading: PropTypes.bool,
  incident: PropTypes.object,
};

export default SyriaMap;
