import React, { useContext } from "react";
import { Router } from "@reach/router";
import Layout from "components/layout";
import { LocaleContext } from "context/locale-context";
import IncidentDetails from "components/datasets/incident-details";

function Incident() {
  const locale = useContext(LocaleContext);
  return (
    <Layout>
      <Router>
        <IncidentDetails path={`/${locale}/datasets/incident/details/:id`} />
      </Router>
    </Layout>
  );
}

export default Incident;
